<template>
    <div class="managsystem-userdata">
        <div class="block-box" v-for="(item, idx) in modules" :key="idx">
            <div class="head-box">
                <div class="title">{{ item.title }}</div>
            </div>
            <div class="content-box">
                <div class="chart-module">
                    <div class="head-box">
                        <div class="date">
                            <DatePicker type="daterange" split-panels :options="datePickerOptions" :value="item.date" placeholder="选择日期" style="width: 200px" @on-change="onChangeDate($event, item)" />
                            <!-- <div class="condition-box">
                                <RadioGroup v-model="item.limit" @on-change="getChartData(item)">
                                    <Radio label="1">按日</Radio>
                                    <Radio label="2">按周</Radio>
                                    <Radio label="3">按月</Radio>
                                    <Radio label="4">季度</Radio>
                                </RadioGroup>
                            </div> -->
                        </div>
                        <div class="download-btn" @click="onExport(item)">
                            <Icon type="md-cloud-download" />
                            <p class="name">下载</p>
                        </div>
                        <div class="more" @click="onDisplayDetail(item)">更多详情>></div>
                    </div>
                    <ElemChart class="chart-box" :option="item.option"></ElemChart>
                </div>
            </div>
        </div>

        <CompModal ref="comp_modal" :title="detail.title" @on-close="onCloseModal" width="80%">
            <div class="managsystem-userdata">
                <div class="block-box">
                    <div class="head-box">
                        <div class="date">
                            <DatePicker
                                type="daterange"
                                split-panels
                                :options="datePickerOptions"
                                placeholder="选择日期"
                                style="width: 200px"
                                v-model="detail.date"
                                @on-change="
                                    getChartData(detail)
                                    getCommunityDetailData(detail)
                                "
                            />
                            <!-- <div class="condition-box">
                                <RadioGroup
                                    v-model="detail.limit"
                                    @on-change="
                                        getChartData(detail)
                                        getCommunityDetailData(detail)
                                    "
                                >
                                    <Radio label="1">按日</Radio>
                                    <Radio label="2">按周</Radio>
                                    <Radio label="3">按月</Radio>
                                    <Radio label="4">季度</Radio>
                                </RadioGroup>
                            </div> -->
                        </div>
                    </div>
                    <div class="content-box">
                        <div class="chart-module">
                            <div class="head-box">
                                <RadioGroup v-model="detail.onlineStatus" @on-change="getChartData(detail)">
                                    <Radio label="">全部社区</Radio>
                                    <Radio label="1">试点社区</Radio>
                                    <Radio label="2">试用社区</Radio>
                                </RadioGroup>
                                <div class="download-btn" @click="onExport(detail)">
                                    <Icon type="md-cloud-download" />
                                    <p class="name">下载</p>
                                </div>
                            </div>
                            <ElemChart class="chart-box" :option="detail.option"></ElemChart>
                        </div>
                    </div>
                    <div class="content-box">
                        <div class="chart-module">
                            <div class="head-box">
                                <CheckboxGroup v-model="detail.community" @on-change="getCommunityDetailData(detail)">
                                    <Checkbox v-for="(item, idx) in communitys" :key="idx" :label="item.orgCode">
                                        <span>{{ item.orgName }}</span>
                                    </Checkbox>
                                </CheckboxGroup>
                                <div class="download-btn" @click="onExportByDate(detail)">
                                    <Icon type="md-cloud-download" />
                                    <p class="name">下载</p>
                                </div>
                            </div>
                            <ElemChart class="chart-box" :option="detail.lineOption"></ElemChart>
                        </div>
                    </div>
                </div>
            </div>
        </CompModal>
    </div>
</template>

<script>
import CompModal from "@/views/residentdatabase/components/CompModal.vue"
import ElemChart from "./childrens/ElemChart.vue"
import xlsx from "xlsx"

import Street from "./utils/street"
import DateUtil from "./utils/date"

export default {
    components: { CompModal, ElemChart },

    data() {
        let date = [DateUtil.getDateByDay(-8), DateUtil.getDateByDay(-1)]

        return {
            // 日期选择器选项
            datePickerOptions: {
                disabledDate: date => {
                    // 判断日期是否大于等于今天，如果是则禁止选择
                    return date && date.valueOf() >= new Date(this.$core.formatDate(new Date(), "yyyy-MM-dd 00:00:00")).valueOf()
                },
            },
            // 模块
            modules: [
                {
                    id: this.$core.randomString(),
                    title: "累计访问用户数据",
                    key: "statRegister",
                    color: "#FC8452",
                    type: "line",
                    limit: "1",
                    date: date,
                },
                {
                    id: this.$core.randomString(),
                    title: "新增访问用户数据",
                    key: "registerNum",
                    color: "#EE6666",
                    type: "bar",
                    limit: "1",
                    date: date,
                },
            ],
            communitys: null,
            detail: {},
        }
    },

    async mounted() {
        let street = await Street.getInfo()
        this.orgPath = street.orgPath
        for (let i = 0, l = this.modules; i < l.length; i++) {
            await this.getChartData(l[i])
        }
    },

    methods: {
        /**
         * 显示详情
         */
        async onDisplayDetail(item) {
            if (!item.detail) {
                // 拷贝数据
                item.detail = Object.assign({}, item, {
                    id: this.$core.randomString(),
                })
            }
            const detail = item.detail
            // 无社区列表则初始列表
            if (!this.communitys) {
                let street = await Street.getInfo()
                this.communitys = street.children
            }
            // 默认第一个社区
            detail.community = [this.communitys?.[0].orgCode]
            // 指定当前详情数据对象
            this.detail = detail
            // 获取数据
            await this.getCommunityDetailData(detail)
            // 显示弹窗
            this.$refs.comp_modal.display()
        },

        /**
         * 获取图表数据
         */
        getChartData(item) {
            return this.$post(
                "/gateway/api/ck/OrgIndicator/statDateUserStreet",
                {
                    dataScopeId: this.orgPath, // 范围编码
                    startDate: this.$core.formatDate(item.date[0], "yyyy-MM-dd"), // 开始时间
                    endDate: this.$core.formatDate(item.date[1], "yyyy-MM-dd"), // 结束时间
                    dateType: item.limit, // 1:日 2:周 3:月 4:季度
                    selectedField: [item.key, "onlineStatus"], // 查询字段
                    onlineStatus: item.onlineStatus, // 上线情况：1:示范社区 2:试用社区 3:非试用社区
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    const date = []
                    const data = []
                    const list = []
                    for (let i = 0, l = res.dataList; i < l.length; i++) {
                        let v = l[i]
                        date.push(v.statDate)
                        data.push(v[item.key])
                        list.push({
                            d: v.statDate,
                            v: v[item.key],
                        })
                    }
                    item.dataList = list
                    // 图表配置
                    this.$set(item, "option", {
                        tooltip: {
                            trigger: "axis",
                        },
                        color: item.color,
                        xAxis: {
                            type: "category",
                            data: date,
                        },
                        yAxis: {
                            type: "value",
                            scale: true,
                        },
                        series: [
                            {
                                name: item.title,
                                type: item.type,
                                smooth: item.type === "line",
                                data: data,
                                markLine: {
                                    data: [
                                        { type: "average" },
                                        [
                                            {
                                                symbol: "none",
                                                x: "90%",
                                                yAxis: "max",
                                            },
                                            {
                                                symbol: "circle",
                                                label: {
                                                    position: "start",
                                                },
                                                type: "max",
                                            },
                                        ],
                                    ],
                                },
                            },
                        ],
                    })
                } else this.$Message.error(res.desc)
            })
        },

        /**
         * 获取社区详情数据
         */
        getCommunityDetailData(item) {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            return this.$post(
                "/gateway/api/ck/OrgIndicator/statDateUserByCommunity",
                {
                    dataScopeId: this.orgPath, //范围编码
                    startDate: this.$core.formatDate(item.date[0], "yyyy-MM-dd"), //开始时间
                    endDate: this.$core.formatDate(item.date[1], "yyyy-MM-dd"), //结束时间
                    communityCode: item.community?.join(",") || "",
                    dateType: item.limit, // 1:日 2:周 3:月 4:季度
                    selectedField: ["orgCode", "orgName", "onlineStatus", "onlineType", item.key], //查询字段
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        const data = res.data
                        const communitys = {}
                        const keys = []
                        const series = []

                        for (let i = 0, ks = Object.keys(data).sort((a, b) => (a > b ? 1 : -1)); i < ks.length; i++) {
                            let key = ks[i]

                            for (let idx = 0, l = data[key]; idx < l.length; idx++) {
                                let v = l[idx]

                                if (!communitys[v.orgName]) {
                                    communitys[v.orgName] = []
                                }

                                communitys[v.orgName].push(v[item.key] || 0)
                            }

                            keys.push(key)
                        }

                        for (let i = 0, ks = Object.keys(communitys); i < ks.length; i++) {
                            let key = ks[i]

                            series.push({
                                name: key,
                                type: "line",
                                smooth: true,
                                data: communitys[key],
                            })
                        }

                        const option = {
                            tooltip: {
                                trigger: "axis",
                            },
                            xAxis: {
                                type: "category",
                                boundaryGap: false,
                                data: keys,
                            },
                            yAxis: {
                                type: "value",
                                scale: true,
                            },
                            series: series,
                        }

                        item.statDateUserByCommunity = data
                        this.$set(item, "lineOption", option)
                    }
                })
                .finally(this.$Message.destroy)
        },

        /**
         * 监听日期变化
         */
        onChangeDate(evt, item) {
            if (!evt || evt.length <= 0) return
            let s = new Date(evt[0])
            let e = new Date(evt[1])
            // 设置开始时间的一年后
            s.setFullYear(s.getFullYear() + 1)
            // 日期范围不能大于一年
            if (s.getTime() < e.getTime()) {
                return this.$Message.error("日期范围最大为一年")
            }
            // 日期范围数据
            item.date = [new Date(evt[0]), e]
            // 请求数据
            this.getChartData(item)
        },

        /**
         * 导出
         */
        onExport(item) {
            const data = []
            var total = 0
            for (let i = 0, l = item.dataList; i < l.length; i++) {
                let v = l[i]
                data.push({
                    序号: i + 1,
                    日期: v.d,
                    数值: v.v,
                })
                // 总数
                total += v.v
            }
            // 合计
            data.push({
                序号: "小计：",
                数据: total,
            })
            // 创建一个新 sheet
            const new_sheet = xlsx.utils.json_to_sheet(data)
            // 配置单元格宽度属性
            new_sheet["!cols"] = [{ wch: 10 }, { wch: 20 }, { wch: 20 }]
            // 配置合并属性
            new_sheet["!merges"] = [{ s: { c: 0, r: data.length }, e: { c: 1, r: data.length } }]
            // 新建 book
            const new_book = xlsx.utils.book_new()
            // 将 sheet 添加到 book 中
            xlsx.utils.book_append_sheet(new_book, new_sheet, item.title)
            // 导出 excel 文件
            xlsx.writeFile(new_book, item.date + "-数据导出.xlsx")
        },

        /**
         * 导出 Excel
         */
        onExportByDate(item) {
            const data = []

            const statData = item.statDateUserByCommunity
            const communitys = {}

            const cols = [{ wch: 10 }, { wch: 20 }, { wch: 20 }]

            for (let i = 0, ks = Object.keys(statData).sort((a, b) => (a > b ? 1 : -1)); i < ks.length; i++) {
                let key = ks[i]

                for (let idx = 0, l = statData[key]; idx < l.length; idx++) {
                    let v = l[idx]

                    if (!communitys[v.orgName]) {
                        communitys[v.orgName] = {
                            序号: Object.keys(communitys).length + 1,
                            类型: Street.getCommunityType(v),
                            社区名称: v.orgName,
                        }
                    }

                    communitys[v.orgName][key] = v[item.key]
                }

                cols.push({ wch: 15 })
            }

            Object.keys(communitys).forEach(v => {
                data.push(communitys[v])
            })

            // 创建一个新 sheet
            const sheet = xlsx.utils.json_to_sheet(data)
            // 配置单元格宽度属性
            sheet["!cols"] = cols
            // 新建 book
            const book = xlsx.utils.book_new()
            // 将 sheet 添加到 book 中
            xlsx.utils.book_append_sheet(book, sheet, item.title)
            // 导出 excel 文件
            xlsx.writeFile(book, item.title + "-数据导出.xlsx")
        },
    },
}
</script>

<style lang="less">
.managsystem-userdata {
    background: #f8f8f8;
    position: relative;
    min-height: 100%;

    .block-box {
        width: 100%;
        padding: 0 20px 10px 20px;
        box-sizing: border-box;
        max-width: 1500px;
        min-width: 700px;
        margin: 0 auto;

        &:first-child {
            padding-top: 20px;
        }

        > .head-box {
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            .title {
                font-size: 20px;
                line-height: 1;
                font-weight: bold;
                color: #333;
            }

            .date {
                display: flex;
                align-items: center;
                background: #fff;
                padding: 10px;
                border-radius: 6px;

                .condition-box {
                    margin-left: 20px;
                }
            }

            .date-filter {
                margin: 0 20px;
            }
        }

        .content-box {
            width: 100%;

            .chart-module {
                margin: 10px 0;
                padding: 10px;
                background: #fff;
                border-radius: 6px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

                .head-box {
                    margin: 10px 10px 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    line-height: 1;

                    > .title {
                        font-size: 16px;
                        font-weight: bold;
                        margin-right: 20px;
                    }

                    .date {
                        flex-grow: 1;
                        color: #999;
                        display: flex;
                        align-items: center;

                        .condition-box {
                            margin-left: 20px;
                        }
                    }

                    .download-btn {
                        flex-shrink: 0;
                        cursor: pointer;
                        padding: 8px 20px;
                        display: flex;
                        align-items: center;
                        background: #e3e3e3;
                        color: #000;
                        border-radius: 4px;
                        margin-right: 20px;

                        &:last-child {
                            margin-right: 0;
                        }

                        .name {
                            margin-left: 5px;
                        }
                    }

                    .more {
                        cursor: pointer;
                        font-weight: bold;
                    }
                }

                .type-box {
                    margin: 10px 10px 0 10px;
                }

                .chart-box {
                    margin-top: 20px;
                    width: 100%;
                    height: 300px;
                }
            }
        }
    }
}
</style>
